import React, { Component } from "react"
import { Link, navigate } from "gatsby"

// Store
import QuestionStore from "~stores/QuestionStore"

// Components
import { Row, Cell } from "~layout/Layout"
import QuestionWrapper from "~components/Questions/QuestionsCommons/QuestionWrapper"
import QuestionMetas from "~components/Questions/QuestionsCommons/QuestionMetas"
import QuestionBtnNext from "~components/Questions/QuestionsCommons/QuestionBtnNext"
import DragDrop from "~components/DragDrops/DragDrop"

// Styles
import Styles from "./QuestionDragdrop.module.styl"

// Libs
import anime from "animejs/lib/anime.es.js"

class QuestionDragdrop extends Component {
    constructor(props) {
        super(props)

        // ES6 Rebind
        this.onDragEnd = this.onDragEnd.bind(this)

        // Refs
        this.questionLeft = React.createRef()
        this.questionRight = React.createRef()
    }

    state = {
        questionData: this.props.pageContext,
        hasDraggedOnce: false,
        scores: [],
        nextQuestion: this.props.pageContext.next,
    }

    componentDidMount() {
        this.checkQuestionFromStore(this.state.questionData.id)

        this.setEnterAnimation()
    }

    setEnterAnimation() {
        const timeline = anime.timeline({
            easing: "easeInOutExpo",
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: ["1rem", 0],
            opacity: [0, 1],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: ["1rem", 0],
                opacity: [0, 1],
                duration: 400,
                complete: (anim) => {
                    if (this.questionRight && this.questionRight.current) {
                        this.questionRight.current.style.transform = ``
                    }
                },
            },
            100
        )
    }

    setExitAnimation(callback) {
        const timeline = anime.timeline({
            easing: "easeInOutExpo",
            delay: 200,
        })

        timeline.add({
            targets: this.questionLeft.current,
            translateX: [0, "-1rem"],
            opacity: [1, 0],
            duration: 400,
        })

        timeline.add(
            {
                targets: this.questionRight.current,
                translateX: [0, "-1rem"],
                opacity: [1, 0],
                duration: 400,
                complete: (anim) => {
                    callback()
                },
            },
            100
        )
    }

    checkQuestionFromStore(index) {
        if (!QuestionStore.checkIfEverAnswered()) {
            navigate(`/questions/1`)
            return
        }

        const foundIndex = QuestionStore.checkForQuestion(
            this.props.pageContext.id
        )

        if (foundIndex > -1) {
            QuestionStore.removeQuestionAtIndex(foundIndex)
        }
    }

    onDragEnd(items) {
        const coeffList = [2, 1, 0.5, 0.25],
            candidates = items.slice(0, this.state.questionData.candidatesLimit)

        let scores = candidates.map((candidate, index) => {
            for (let i = 0, j = candidate.jars.length; i < j; i++) {
                let currentJar = candidate.jars[i]

                return {
                    label: candidate.content,
                    jar: currentJar,
                    score: candidate.score * coeffList[index],
                }
            }
        })

        this.setState({
            hasDraggedOnce: true,
            scores,
        })
    }

    goToNext() {
        QuestionStore.updateScore(this.state.questionData.id, this.state.scores)

        this.setExitAnimation(() => {
            navigate(`/questions/${this.state.nextQuestion}`)
        })
    }

    render() {
        return (
            <QuestionWrapper
                currentQuestionID={this.state.questionData.id}
                currentTheme={this.state.questionData.theme}
            >
                <div className={Styles.QuestionDragDrop}>
                    <div className={Styles.QuestionDragDrop_inner}>
                        <Row>
                            <Cell start="1" end="5" startTl="0" endTl="12">
                                <div
                                    className={Styles.QuestionDragDrop__left}
                                    ref={this.questionLeft}
                                >
                                    <QuestionMetas
                                        data={this.state.questionData}
                                    />
                                </div>
                            </Cell>
                            <Cell
                                start="6"
                                end="10"
                                startD="6"
                                endD="11"
                                startTl="0"
                                endTl="12"
                            >
                                <div
                                    className={Styles.QuestionDragDrop__right}
                                    ref={this.questionRight}
                                >
                                    <DragDrop
                                        onDragEnd={this.onDragEnd}
                                        data={this.state.questionData}
                                    />
                                </div>
                            </Cell>
                        </Row>
                    </div>
                </div>

                {this.state.hasDraggedOnce && (
                    <QuestionBtnNext
                        onClick={this.goToNext.bind(this)}
                        label={"Confirmer"}
                    />
                )}
            </QuestionWrapper>
        )
    }
}

export default QuestionDragdrop
