import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

// Styles
import Styles from "./DragDrop.module.styl"

// Components
import Icon from "~icons/Icon"

// Helpers
import SptkUtils from "~helpers/sptk"

// Libs
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"

// fake data generator

const shuffleArray = (choices) => {
    for (let i = choices.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))

        ;[choices[i], choices[j]] = [choices[j], choices[i]]
    }
    return choices
}

const getItems = (choices) => {
    const shuffledChoices = shuffleArray(choices)

    const items = shuffledChoices.map((choice, index) => {
        return {
            id: `item-${index}`,
            score: `${choice.score}`,
            jars: choice.jars,
            content: `${choice.title}`,
        }
    })

    return items
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const grid = 8
let marginScalar = 3

const getItemStyle = (isDragging, draggableStyle) => {
    const device = new SptkUtils()
    let marginScalar = 3

    if (device.utils.isMobileTablet) {
        marginScalar = 1
    }

    return {
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        margin: `0 0 ${grid * marginScalar}px 0`,

        // styles we need to apply on draggables
        ...draggableStyle,
    }
}

const getListStyle = (isDraggingOver) => ({
    width: "100%",
})

class DragDrop extends Component {
    constructor(props) {
        super(props)

        this.device = new SptkUtils()

        this.state = {
            items: getItems(this.props.data.choices),
        }

        this.onDragEnd = this.onDragEnd.bind(this)
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks()
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        // enableBodyScroll(document.body)

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        )

        this.setState({
            items,
        })

        this.props.onDragEnd(items)
    }

    onDragStart() {
        console.log("dragStart")
        // disableBodyScroll(document.body)
    }

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        return (
            <DragDropContext
                onDragStart={this.onDragStart}
                onDragEnd={this.onDragEnd}
            >
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {this.state.items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={`${
                                                Styles.DragDrop__item
                                            } ${
                                                snapshot.isDragging
                                                    ? Styles.DragDrop__item__active
                                                    : ""
                                            } ${
                                                index <
                                                this.props.data.candidatesLimit
                                                    ? Styles.DragDrop__item__eligible
                                                    : ""
                                            }`}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                            data-index={index + 1}
                                        >
                                            <div
                                                className={
                                                    Styles.DragDrop__item__content
                                                }
                                            >
                                                <span
                                                    className={`teasing-1 u-f-reset-case ${Styles.DragDrop__item__label}`}
                                                >
                                                    {item.content}
                                                </span>
                                                <Icon
                                                    name={`${
                                                        this.device.utils
                                                            .isMobileTablet
                                                            ? "drag-mobile"
                                                            : "drag"
                                                    }`}
                                                    width={16}
                                                    height={16}
                                                    fill="#000"
                                                    extraClasses={
                                                        Styles.DragDrop__item__icon
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

export default DragDrop
